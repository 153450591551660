<template>
    <div class="editor">
        <div class="head">
            <slot name="head">
                <div class="title">{{ title }}</div>
            </slot>
        </div>
        <div class="tabs" v-if="tabs && tabs.length > 0">
            <div
                v-for="(tab,index) in tabs"
                class="tab"
                :key="index"
                :class="{current : index === currentTabIndex}"
                @click="setCurrentTabIndex(index)"
            >
                {{ tab.props.title }}
            </div>
        </div>
        <div class="body">
            <slot>
                <div class="info">
                    <div class="fields">
                        <div class="field" v-for="field in fields" :key="field.key">
                            <input-box
                                v-if="field.type === 'phone'"
                                v-model="data[field.key]"
                                type="phone"
                                maxlength="12"
                                :required="field.required"
                                :label="field.label"
                                :error="checkError(field.key)"
                            ></input-box>
                            <check-box
                                v-else-if="field.type === 'checkbox'"
                                v-model="data[field.key]"
                                :label="field.label"
                                :error="checkError(field.key)"
                            ></check-box>
                            <input-box
                                v-else
                                v-model="data[field.key]"
                                :type="field.type ?? 'text'"
                                :label="field.label"
                                :error="checkError(field.key)"
                            ></input-box>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div class="foot">
            <div class="button-box" @click="save">Сохранить</div>
            <div class="button-box grey" @click="close">Закрыть</div>
        </div>
    </div>
</template>

<script>
import InputBox from "../fields/InputBox";
import CheckBox from "../fields/CheckBox";

export default {
    name: "Editor",
    components: {CheckBox, InputBox},
    props: {
        id: [Number, null],
        title: String,
        fields: Array,
        errors: Array,
        item: Object
    },
    methods: {
        save() {
            this.hasError = [];
            this.data['id'] = this.id;
            this.$emit('save', this.data)
        },
        checkError(key) {
            return !!this.hasError.find(error => error.key === key);
        },
        close() {
            this.$emit('close');
        },
        setCurrentTabIndex(activeIndex) {
            this.currentTabIndex = activeIndex;
            this.tabs.forEach((tab, index) => {
                tab.props.active = (index === activeIndex);
                if (index === activeIndex) {
                    this.currentTabTitle = tab.props.title;
                }
            })
        },
        getCurrentTabTitle() {
            return this.currentTabTitle;
        }
    },
    watch: {
        item(value) {
            this.data = value;
        },
        errors(value) {
            this.hasError = value;
        }
    },
    data() {
        return {
            data: {},
            hasError: [],
            tabs: [],
            currentTabIndex: 0,
            currentTabTitle: ''
        }
    },
    created() {
        if (this.$slots.default !== undefined) {
            this.tabs = this.$slots
                .default()
                .filter(slot => slot.type.name === 'EditorTab');
            if (this.tabs.length) {
                this.currentTabTitle = this.tabs[0].props.title;
            }
        }
    },
    provide() {
        return {
            getCurrentTabTitle: this.getCurrentTabTitle
        }
    }
}
</script>
