<template>
    <modal ref="editor">
        <editor
            :title="editor.title"
            :fields="editor.fields"
            :errors="editor.errors"
            :item="editor.item"
            :id="editor.id"
            @save="save"
            @close="closeEditor"
        >
        </editor>
    </modal>
    <div class="sidebar">
        <div class="title">Словари</div>
        <ul class="list-navigation">
            <li
                v-for="dictionary in dictionaries"
                :key="dictionary.key"
                :class="{current: dictionary.key === currentDictionaryKey}"
                @click="changeDictionary(dictionary.key)"
            >
                {{ dictionary.label }}
            </li>
        </ul>
    </div>
    <div class="content">
        <div class="title">{{ currentDictionary.label }}</div>
        <listing
            :loading="loading"
            :items="listing.items"
            :fields="listing.fields"
            :pagination="listing.pagination"
            @change-page="changePage"
            @add-item="addItem"
            @edit-item="editItem"
            @remove-item="removeItem"
            @change-query="changeQuery"
            @apply-filters="loadItems"
            @close-filters="loadItems"
        ></listing>
    </div>
</template>

<script>
import { DictionariesService } from "../services/dictionaries.service";
import Listing from "../components/listing/Listing";
import Editor from "../components/editor/Editor";
import Modal from "../components/Modal";

export default {
    name: "Dictionaries",
    components: {Modal, Editor, Listing},
    methods: {
        changeDictionary(key) {
            this.loading = true;
            this.$router.push({
                name: 'Dictionaries',
                params: {
                    dictionary: key
                }
            });
            this.currentDictionaryKey = key;
            this.service.key = key;
            this.loadItems();
        },
        loadItems() {
            this.loading = true;
            this.service.get(this.listingParams).then(response => {
                this.listing.items = response.data;
                this.listing.pagination = response.pagination;
                this.listing.fields = response.listing;
                this.editor.fields = response.editor;
                this.loading = false;
            });
        },
        changePage(page) {
            this.listing.page = page;
        },
        addItem() {
            this.editor.title = 'Добавить запись';
            this.editor.item = {};
            this.editor.id = null;
            this.$refs.editor.show();
        },
        editItem(id) {
            this.editor.id = id;
            this.service.one({
                filters: {
                    id: this.editor.id
                }
            }).then(response => {
                this.editor.title = 'Редактировать запись';
                this.editor.item = response.data;
                this.editor.fields = response.editor;
                this.$refs.editor.show();
            });
        },
        removeItem(id) {
            this.service.remove({
                filters: {
                    id: id
                }
            }).then(response => {
                if (response.success) {
                    this.loadItems();
                }
            });
        },
        save(response) {
            this.service.save(response).then(response => {
                if (response.success === true) {
                    this.loadItems();
                    this.$refs.editor.hide();
                    this.editor.id = null;
                } else {
                    this.editor.errors = response.errors;
                }
            });
        },
        closeEditor() {
            this.$refs.editor.hide();
        },
        changeQuery(query) {
            this.listing.query = query;
        }
    },
    computed: {
        listingParams() {
            let params = {
                page: this.listing.page,
                filters: []
            };
            if (this.listing.query) {
                params.filters.name = {
                    operator: 'LIKE',
                    value: '%' + this.listing.query + '%'
                }
            }

            return params;
        },
        currentDictionary() {
            return this.dictionaries.find(dictionary => dictionary.key === this.currentDictionaryKey);
        }
    },
    data() {
        return {
            service: DictionariesService,
            dictionaries: [
                {
                    key: 'stores',
                    label: 'Склады'
                },
                {
                    key: 'prices',
                    label: 'Типы цен'
                },
                {
                    key: 'options',
                    label: 'Характеристики'
                },
                {
                    key: 'tags',
                    label: 'Теги'
                },
                {
                    key: 'statuses',
                    label: 'Статусы'
                }
            ],
            currentDictionaryKey: 'stores',
            loading: false,
            listing: {
                items: [],
                fields: [],
                page: 1,
                pagination: {},
                query: ''
            },
            editor: {
                id: null,
                item: {},
                title: '',
                fields: [],
                errors: [],
            }
        }
    },
    mounted() {
        this.currentDictionaryKey = this.$route.params.dictionary ?? this.dictionaries[0].key;
        this.changeDictionary(this.currentDictionaryKey);
    }
}
</script>

<style scoped>

</style>