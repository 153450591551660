import { reactive } from "vue";
import HttpService from "./http.service";

export const DictionariesService = reactive({
    key: '',
    tags: [],
    statuses: [],
    stores: [],
    prices: [],
    get(data) {
        return HttpService.get(this.key + '/all', data);
    },
    one(data) {
        return HttpService.get(this.key + '/one', data)
    },
    save(data) {
        return HttpService.post(this.key + '/save', data);
    },
    remove(data) {
        return HttpService.post(this.key + '/remove', data);
    },
    autocomplete(data) {
        return HttpService.get(this.key + '/autocomplete', data);
    },
    loadTags() {
        this.key = 'tags';
        this.get().then(response => {
            this.tags = response.data
        });
    },
    loadStatuses() {
        this.key = 'statuses';
        this.get().then(response => {
            this.statuses = response.data
        });
    },
    loadStores() {
        this.key = 'stores';
        this.get().then(response => {
            this.stores = response.data
        });
    },
    loadPrices() {
        this.key = 'prices';
        this.get().then(response => {
            this.prices = response.data
        });
    }
})

DictionariesService.loadTags();
DictionariesService.loadStatuses();
DictionariesService.loadStores();
DictionariesService.loadPrices();