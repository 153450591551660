<template>
    <label class="input-box" :class="inputClasses">
        <div class="label" v-if="label.length">{{ label }}</div>
        <input
            type="checkbox"
            v-model="value"
            :value="val"
        >
    </label>
</template>

<script>
export default {
    name: "CheckBox",
    props: {
        modelValue: {
            type: [String, Number, Boolean, Array],
            default: 0
        },
        label: {
            type: String,
            default: ""
        },
        val: {
            type: String,
            default: ""
        },
        success: Boolean,
        error: Boolean,
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        inputClasses() {
            return {
                'with-label': !!this.label,
                'filled': !!this.modelValue,
                'success': this.success,
                'has-error': this.error
            }
        },
    }
}
</script>
